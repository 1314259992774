import "./admin.css";
import React, {useState,UseEffect, useEffect} from 'react';
import LoginForm from "../component/LoginForm"


export const AdminLogin = (props) => {
    return (
       <LoginForm/>
    );
}
export default AdminLogin;
